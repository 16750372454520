import React from 'react'

const ServicesTableRow = ({data}) => {
    const {vorname,nachname,straße,plz,stadt} = data
  return (
    <tr class="border-b border-solid border-gray-200 bg-white hover:bg-gray-50 text-[#222222]">
    <td
      scope="row"
      class="whitespace-nowrap px-2 py-2 font-medium text-gray-900"
    >
      <p>
        <span>{vorname}</span>
        <span>{nachname}</span>
      </p>
    </td>

    <td class="px-2 py-2 capitalize">{straße}</td>
    <td class="px-2 py-2">{plz}</td>

   <td class="px-2 py-2">{stadt}</td>
   
  </tr>
  )
}

export default ServicesTableRow